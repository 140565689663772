<template>
  <div class="footerindexbox"> 
    <div class="footer_details">
      <div class="base_w">
        <div class="dd">
          <div class="divbox flex_column_start divbox1">
            <span class="title">我是公众</span>
            <span @click.stop="tourl('/index')" class="div1span">勤问首页</span>
            <span @click.stop="tourl('/toask')" class="div1span">发布咨询</span>
            <span @click.stop="tourl('/zhaolvshi')" class="div1span">找律师</span>
            <span @click.stop="tourl('/falvzixun')" class="div1span">法律咨询</span>
            <span @click.stop="tourl('/redianzixun')" class="div1span">热点资讯</span>
          </div>
          <div class="divbox flex_column_start">
            <span class="title">微信端</span>
            <img src="@/assets/img/wx_code.jpg" alt="" >
          </div>
          <div class="divbox flex_column_start">
            <span class="title">抖音端</span>
            <img src="@/assets/img/zijie_code.png" alt="" >
          </div>
          <div class="divbox flex_column_start">
            <span class="title">快手端</span>
            <img src="@/assets/img/ks_code.png" alt="" >
          </div>
  
          <div class="divbox flex_column_start">
            <span class="title">百度端</span>
            <img src="@/assets/img/baidu_code.png" alt="" >
          </div>
          <div class="line"></div>
  
          <div class="divbox flex_column_start divbox2">
            <span class="title">我是律师</span>
            <span>律师入驻请使用小程序扫码</span>
            <span>律师合作请联系客服</span>
            <span>服务时间：9:00-18:00</span>
            <span>邮箱：32425008@qq.com</span>
            <!-- <span>电话：18200889911</span> -->
          </div>
    
          <div class="divbox flex_column_start">
            <span class="title">勤问律师APP (安卓版)</span>
            <img src="@/assets/img/app_code.png" alt="" >
          </div>
        </div>
        <p class="webmap">
          网站地图：
          <span @click.stop="tourl('/index')">勤问律师网站</span>|
          <span @click.stop="tourl('/falvzixun')">法律咨询</span>|
          <span @click.stop="tourl('/zhaolvshi')">找律师</span>|
          <span @click.stop="tourl('/redianzixun')">法律资讯</span>|
          <span @click.stop="tourl('/yonghu')">用户协议</span>|
          <span @click.stop="tourl('/yinsi')">隐私协议</span>
        </p>
      </div> 
    </div>
    <div class="footer_bottom">
      <div class="base_w">
        <p class="spanbox">
          <span>2022-2035湖北律仕堂版权所有</span>
          <!-- <span>  鄂ICP备2021011407号</span> -->
          <span> 
            <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2021011407号-1</a>
          </span>
          <span>工业和信息化部备案管理系统网站</span>
        </p>
        <p>联系地址：武汉东湖新技术开发区光谷大道41现代国际设计城一期3栋11层02号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  // props: {
  //   type: {
  //     type: String,
  //     default: '1'
  //   }
  // },
  data() {
    return {
     
    }
  },

  mounted() {},

  methods: { 
    // 跳转
    tourl(url) {
      if(window.location.hash.indexOf(url) > -1){
        return;
      }

      this.$router.push({
        path: url
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.footerindexbox{
  
}
.footer_details{
  background: #30343E;
  // height: 483px;
  padding: 62px 0;
  box-sizing: border-box;
  .dd{
    display: flex;
    justify-content: space-between;
  }
  .divbox{
    .title{
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      margin-bottom: 32px;
      text-align: center;
    }
    img{
      width:174px;
      height: 166px;
    }
    span{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #969AA8;
    }
  }
  .divbox1{
    span{
      margin-bottom: 16px;
    }
    .div1span:hover{
      color: #305BFE;
      cursor: pointer;
    }
  }
  .divbox2{
    span{
      margin-bottom: 16px;
    }
    span:nth-child(2){
      margin-bottom: 25px;
      padding-top: 16px;
    }
  }
  .line{
    width: 1px;
    height: 195px;
    background:  #4A4E59;
  }
  .webmap{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969AA8;
    margin-top: 47px;
    span{
      padding: 0 10px;
      cursor: pointer;
    }
    span:hover{
      color: #305BFE;
    }
  }
 
}
.footer_bottom{
  height: 88px;
  background: #282C33;
  padding: 20px 0;
  p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939599;
    text-align: center;
    span{
      margin-right: 16px;
    }
  }
  .spanbox{
    margin-bottom: 8px;
    a{
      color: #939599;
      &:hover{
        color: #fff;
      }
    }
  }
 
}

</style>