<template>
  <div class="headerindexbox">
    <!-- 登录注册 -->
    <div class="userlogin">
      <div class="base_w">
        <div class="flexbox">
          <div class="flexbox" v-if="islogin">
            <img :src="userinfo.photo" alt="" class="avatarimg" @click="shownav" v-if="userinfo.photo"/>
            <img src="../assets/img/avatar.png" alt="" class="avatarimg" @click="shownav" v-else>
            <div class="loginspan"  @click="shownav">
              {{isqiyepass==true ? userinfo.qiyeName : userinfo.nikname}} 
            </div>
          </div>
          
          <div class="loginspan flexbox" v-else>
            <img src="../assets/img/avatar.png" alt="" class="avatarimg">
            <span @click.stop="login">登录</span> /
            <span @click.stop="register">注册</span>
          </div>
          <div class="messagebox" @click.stop="tourl('/msg')">
            <el-badge :value="count" :max="99" class="item" :hidden="count <= 0 ? true:false">
              <img src="@/assets/img/message.png" alt="" class="messageimg" />
            </el-badge>
           
          </div>
        </div>
        <!-- 个人中心导航 -->
        <div class="centernav flex_column_center" v-if="nav">
          <span
            :class="[show1 ? 'navactive' : '']"
            @mouseenter="changeChan(1)"
            @mouseout="changeChanBack(1)"
            @click.stop="changeItem(-1, '/gerenziliao')"
            >个人资料</span
          >
          <span
            :class="[show2 ? 'navactive' : '']"
            @mouseenter="changeChan(2)"
            @mouseout="changeChanBack(2)"
            @click.stop="changeItem(-1, '/wodeqianbao')"
            >我的钱包
          </span>
          <span
            :class="[show3 ? 'navactive' : '']"
            @mouseenter="changeChan(3)"
            @mouseout="changeChanBack(3)"
            @click.stop="changeItem(-1, '/zixun')"
            >我的咨询</span
          >
          <span
            :class="[show4 ? 'navactive' : '']"
            @mouseenter="changeChan(4)"
            @mouseout="changeChanBack(4)"
            @click.stop="changeItem(-1, '/follow')"
            >我的收藏/关注</span
          >
          <span
            :class="[show5 ? 'navactive' : '']"
            @mouseenter="changeChan(5)"
            @mouseout="changeChanBack(5)"
            @click.stop="changeItem(-1, '/authentify')"
            >企业认证</span
          >
          <!--   v-if="gerenshow == false" -->
          <span
            :class="[show6 ? 'navactive' : '']"
            @mouseenter="changeChan(6)"
            @mouseout="changeChanBack(6)"
            @click.stop="changeItem(-1, '/member')"
            >会员中心</span
          >
          <span
            :class="[show7 ? 'navactive' : '']"
            @mouseenter="changeChan(7)"
            @mouseout="changeChanBack(7)"
            @click.stop="changeItem(-1, '/card')"
            >银行卡</span
          >
          <span
            :class="[show8 ? 'navactive' : '']"
            @mouseenter="changeChan(8)"
            @mouseout="changeChanBack(8)"
            @click.stop="logout"
            >退出登录</span
          >
        </div>
      </div>
    </div>

    <!--  顶栏 -->
    <div class="topheader">
      <div class="base_w flex_between">
        <div class="logobox flexbox">
          <img src="@/assets/img/logo.png" alt="" class="logoimg" />
        </div>
        <div class="navmenu flex_around">
          <span
            :class="[type == 1 ? 'active' : '']"
            @click.stop="changeItem(1, '/index')"
            >首页</span
          >
          <span
            :class="[type == 2 ? 'active' : '']"
            @click.stop="changeItem(2, '/falvzixun')"
            >法律咨询</span
          >
          <span
            :class="[type == 3 ? 'active' : '']"
            @click.stop="changeItem(3, '/falvwenshu')"
            >法律文书</span
          >
          <span
            :class="[type == 4 ? 'active' : '']"
            @click.stop="changeItem(4, '/zhaolvshi')"
            >找律师</span
          >
          <span
            :class="[type == 5 ? 'active' : '']"
            @click.stop="changeItem(5, '/wenfa')"
            >问法</span
          >
          <span
            :class="[type == 6 ? 'active' : '']"
            @click.stop="changeItem(6, '/redianzixun')"
            >热点资讯</span
          >
          <span
            :class="[type == 7 ? 'active' : '']"
            @click.stop="changeItem(7, '/monitor')"
            >侵权监测</span
          >
        </div>
        <div class="soubox">
          <el-input
            placeholder="搜律师"
            v-model="input"
            class="input-with-select"
          >
            <div class="flex_between" slot="append" @click.stop="tosearch('/lvlist?searchname=' + input)">
              <img src="@/assets/img/search.png" alt="" class="searchimg" />
            </div>
          </el-input>
        </div>
      </div>
    </div>

    <loginbox ref="loginbox"></loginbox>
  </div>
</template>

<script>
import loginbox from '@/components/login'
import apiUrl from '@/api/login'
import { mapState } from 'vuex'
export default {
  name: 'index',
  components: { loginbox },
  props: {
    // 1 首页 2 法律咨询 3 法律服务 法律文书 4 找律师 5 问法 6 热点资讯
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      input: '', // 搜索搜律师
      islogin: false, // 是否登录
      nav: false, // 个人中心导航栏是否出现
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      isqiyepass:false, // 企业审核是否通过
      gerenshow: true, // false 企业用户 true 个人用户

      userinfo:{}, // 用户信息
    }
  },
  computed: {
   //  this.$store.state.count  新消息提醒数
  // mapState 把全局  count 变成 可以直接使用的 数据
    ...mapState(['count'])
  },

  mounted() {
    if(localStorage.getItem('userId')){
      this.getuserinfo();
    }
  },

  methods: {
    // 获取用户信息
    getuserinfo() {
      console.log('用户个人信息');
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.userInfo(data).then((res) => {
          if(res.code == 0){
            this.islogin = true;
            this.userinfo = res.data;
            if(res.data.usertype == '企业用户'){
              this.getqiyeinfo();
              this.gerenshow = false;
            }else {
              this.gerenshow = true;
            }
          } else {
            this.islogin = false;
          }
      });
    },
    // 企业审核详情 是否认证成功  status 空3 未提交  0未审核,审核中  1审核通过  2拒绝 
    getqiyeinfo(){
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.qiyeinfo(data).then((res) => {
          if(res.code == 0){
            if(res.data.status == 1){
						  this.isqiyepass = true;
            }else {
              this.isqiyepass = false;
            }
          }
      });
    },
    //  登录
    login() {
      this.$refs.loginbox.open(1);
    },
    // 注册
    register() {
      this.$refs.loginbox.open(2);
    },
    // 主菜单栏切换
    changeItem(data, url) {
      this.nav = false
      if (window.location.hash.indexOf(url) > -1) {
        return
      }

      this.type = data
      this.$router.push({
        path: url
      })
    },
    // 点击个人昵称切换
    shownav() {
      if(!this.islogin){
        return
      }
      this.nav = !this.nav;
    },
    // 鼠标移入
    changeChan(e) {
      this[`show${e}`] = true
    },
    // 鼠标移出
    changeChanBack(e) {
      this[`show${e}`] = false
    },
    // 跳转
    tourl(url) {
      if(window.location.hash.indexOf(url) > -1){
        return;
      }
      if(!this.islogin){
        this.$refs.loginbox.open(1);
        return;
      }
      this.$router.push({
        path: url
      });
    },
    // 搜律师
    tosearch(url){
      if(window.location.hash.indexOf('/lvlist?') > -1){
        this.$router.push({
          path: url
        });
      this.$emit('refresh');
        
      }else {
        this.$router.push({
          path: url
        })
      }
    },
    // 退出
    logout(){
      let that = this;
      localStorage.clear();
      that.islogin = false;
      that.nav = false;
      let promise = this.$tim.logout();
      promise.then(function(imResponse) {
          that.$message({
            message: '登出成功',
            type: 'success'
          });
          location.reload();
				}).catch(function(imError) {
					console.warn('logout error:', imError);
				});
    }
    
  }
}
</script>

<style lang="scss" >
.headerindexbox {
  .userlogin {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    .base_w {
      position: relative;
    }
    .flexbox {
      justify-content: flex-end;
    }
    .avatarimg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .loginspan {
      // margin-left: 10px;
      margin-right: 33px;
      cursor: pointer;
      font-size: 16px;
    }
    .messagebox {
      position: relative;
      width: 23px;
      height: 30px;
      cursor: pointer;
      // div {
      //   position: absolute;
      //   top: -2px;
      //   right: -3px;
      //   line-height: 13px;
      //   width: 13px;
      //   height: 13px;
      //   text-align: center;
      //   background-color: #f34933;
      //   color: #fff;
      //   border-radius: 50%;
      //   font-size: 11px;
      //   font-weight: 500;
      // }
      .messageimg {
        width: 23px;
        height: 23px;
      }
    }
  }

  // 顶栏
  .topheader {
    background-color: #fff;
    // height: 67px;
    // line-height: 67px;
    height: 80px;
    line-height: 80px;
    .logobox {
      width: 183px;
    }
    .logoimg {
      // width: 135px;
      // height: 52px;
      width: 162px;
      height: 62px;
    }
    .navmenu {
      span {
        font-size: 18px;
        margin-right: 60px;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        padding: 0 5px;
      }
      span:last-child {
        margin-right: 0px;
      }
      .active {
        border-bottom: 3px solid #305bfe;
      }
    }
    .soubox {
      .el-input-group {
        border-radius: 10px;
        border: 1px solid #305bfe;
      }
      .el-select .el-input {
        width: 130px;
      }
      .el-input-group > .el-input__inner {
        height: 33px;
      }
      .el-input__inner {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: 0px solid #fff;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
      .el-input-group__append {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #fff;
        border: 0px solid #fff;
      }
      .searchimg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// 个人中心导航
.centernav {
  position: absolute;
  right: 0;
  top: 52px;
  z-index: 100;
  width: 232px;
  background: #ffffff;
  box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding:16px;
  text-align: center;
  font-size: 18px;
  span {
    cursor: pointer;
    height: 56px;
    line-height: 56px;
  }
}
.navactive {
  width: 100%;
  color: #fff;
  background-color: #305bfe;
  border-radius: 10px;
}
</style>